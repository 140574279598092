<template>
  <div>
    <div class="padding">
      <p>第一步： 请点击下面的链接下载Excel模板，并按照模板填写信息</p>
      <a class="blue-color text-hover" href="https://ywjy.oss-cn-hangzhou.aliyuncs.com/default/%E5%AF%BC%E5%85%A5%E4%B8%B4%E6%97%B6%E4%BC%9A%E5%91%98%E6%A8%A1%E7%89%88.xlsx">下载模板</a>
      <p>第二步： 导入Excel模板文件</p>
      <span class="blue-color text-hover" @click="$refs.file.click()">选取文件</span>
      <span class="margin-left red-color" v-if="fileName">已选：{{ fileName }}</span>
      <input type="file" style="visibility: hidden;" @change="fileChange($event)" ref="file"
             accept=".xlsx,.xls">
    </div>
    <div class="modal-bottom">
      <Button :loading="loading" @click="submit" class="float-right" color="primary">确认</Button>
      <Button :loading="loading" @click="reset">取消</Button>
    </div>
  </div>
</template>

<script>
import Merchant from "@/api/Merchant";

export default {
  name: "ImportMember",
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: false,
      fileName: null,
    }
  },
  methods: {
    fileChange() {
      this.fileName = this.$refs.file.files[0].name;
    },
    submit() {
      if (this.$refs.file.files.length > 0) {
        let formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        formData.append('merchantId', this.id);
        this.loading = true;
        Merchant.import(formData).then(({data, success}) => {
          if (success) {
            this.$Message("亲,导入成功~");
            this.$emit('close');
          }
        }).finally(() => {
          this.loading = false;
        });
        this.fileName = null;
        this.$refs.file.value = "";
      }
    },
    reset() {
      this.$emit('close');
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.modal-bottom {
  padding: 20px;
}
</style>
